import moment from 'moment';
import { isString } from 'lodash';

export const formatTimeToUtc = ({ date, timeZone, hoursTime }) => {
  if (date === null || date === 'null') return null;
  if (isString(date)) return date;
  const newDate = date;
  let hours = 0;
  let minutes = 0;

  if (hoursTime) {
    [hours, minutes] = hoursTime.split(':') ?? [0, 0];
  }
  newDate.setHours(hours);
  newDate.setMinutes(minutes);

  const momentDate = moment(newDate);
  const offset = moment(newDate).tz(timeZone).utcOffset();
  const offsetInHours = moment(newDate).utcOffset(offset).format('Z');

  return momentDate.format('YYYY-MM-DDTHH:mm:ss') + offsetInHours;
};
