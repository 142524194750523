/* eslint-disable react/prop-types */
/* eslint-disable */
import React, { memo, useEffect, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { components } from 'react-select';
import { useWindowDimensions } from 'hooks';
import { Wrapper, Label, ErrorWrapper, ErrorMessage, StyledSelect, HorizontalBorder } from './styles';
import {
  CustomRemoveOption,
  CustomRemoveAllOptions,
  CustomInput,
  CustomDropdownIndicator,
  Option,
} from './_components';

const SelectSearchableContainer = ({ children, ...props }) => {
  return <components.SelectContainer {...props}>{children}</components.SelectContainer>;
};

// TODO: Rewrite using ref
const SelectContainer = ({ inputId, isFocus, ...props }) => {
  const { isFocused } = props;

  useEffect(() => {
    if (!isFocused || !inputId?.length) return;
    const el = document.querySelector(`#${inputId}`);
    el.scrollIntoView({ block: 'center' });
    el.focus();
  }, [inputId, isFocused]);

  return <components.SelectContainer autoFocus={isFocus} {...props} />;
};

// specify props.allowSelectAll = true to enable!
const BaseSelect = (props) => {
  const [isFocus, setIsFocus] = useState(false);
  const [isSelectedAll, setIsSelectedAll] = useState(false);
  const { width: viewportWidth } = useWindowDimensions();
  const {
    options,
    allOption,
    onChange,
    allowSelectAll,
    isExportVisit,
    value,
    isDisabled,
    objStyles,
    menuPlacement,
    inputId,
    autoFocus,
    smallDrop,
    labelText,
    required,
    error,
    isClearable,
    pageColumns,
    hasBorder,
  } = props;

  const selectMenuPlacement = useMemo(
    () => (viewportWidth < 850 ? menuPlacement : 'bottom'),
    [menuPlacement, viewportWidth],
  );

  const ref = useRef();

  const handleAll = (selected) => {
    const filteredValue = selected?.filter(({ value }) => value !== allOption.value);
    if (isExportVisit && filteredValue.length === options.length) {
      setIsSelectedAll(false);
      onChange([]);
      return;
    }
    setIsSelectedAll(false);
    onChange(filteredValue);
  };

  useEffect(() => {
    if (!autoFocus) return;
    setIsFocus(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChange = (selected) => {
    if (selected?.length > 0 && selected[selected.length - 1]?.value === allOption.value) {
      setIsSelectedAll(true);
      return onChange(options);
    }
    if (selected?.length > 1 && selected[0].value === allOption.value) {
      setIsSelectedAll(false);
      const filteredValue = selected.slice(-1);
      return onChange(filteredValue);
    }
    return onChange(selected);
  };

  const customStyles = {
    placeholder: (provided) => ({
      ...provided,
    }),
    singleValue: (provided) => ({
      ...provided,
    }),
    ...objStyles,
  };

  const propsSet = {
    defaultMenuIsOpen: false,
    tabSelectsValue: false,
    isDisabled,
    isClearable,
    menuShouldScrollIntoView: true,
    classNamePrefix: 'BaseSelect',
    components: {
      MultiValueRemove: CustomRemoveOption,
      ClearIndicator: (props) => <CustomRemoveAllOptions setIsFocus={setIsFocus} {...props} />,
      DropdownIndicator: CustomDropdownIndicator,
      Option,
    },
    menuPlacement: selectMenuPlacement,
    onBlur: () => setIsFocus(false),
    ...objStyles,
  };

  const searchablePropSet = {
    defaultMenuIsOpen: false,
    tabSelectsValue: false,
    isDisabled,
    isClearable,
    menuShouldScrollIntoView: true,
    classNamePrefix: 'BaseSelect',
    menuPlacement: selectMenuPlacement,
    onBlur: () => setIsFocus(false),
    ...objStyles,
  };

  const wrapperPropsSet = {
    ref,
    smallDrop: smallDrop && smallDrop,
  };

  if (allowSelectAll) {
    if (isSelectedAll) {
      return (
        <Wrapper {...wrapperPropsSet} pageColumns={pageColumns} isBaseSelect>
          {labelText && (
            <Label>
              {labelText} {required && ' *'}
            </Label>
          )}
          <ErrorWrapper className={error ? 'error' : ''}>
            <StyledSelect
              {...props}
              {...propsSet}
              value={isExportVisit ? [allOption, ...options] : [allOption]}
              styles={customStyles}
              onChange={handleAll}
            />
            <ErrorMessage aria-label="error">{error && error}</ErrorMessage>
          </ErrorWrapper>
          {hasBorder && <HorizontalBorder />}
        </Wrapper>
      );
    }

    return (
      <Wrapper {...wrapperPropsSet} pageColumns={pageColumns} isBaseSelect>
        {labelText && (
          <Label>
            {labelText} {required && ' *'}
          </Label>
        )}
        <ErrorWrapper className={error ? 'error' : ''}>
          <StyledSelect
            {...props}
            {...propsSet}
            styles={customStyles}
            options={[allOption, ...options]}
            onChange={handleChange}
            value={value}
          />
          <ErrorMessage aria-label="error">{error && error}</ErrorMessage>
        </ErrorWrapper>
        {hasBorder && <HorizontalBorder />}
      </Wrapper>
    );
  }

  return (
    <Wrapper {...wrapperPropsSet} pageColumns={pageColumns}>
      {labelText && (
        <Label>
          {labelText} {required && ' *'}
        </Label>
      )}
      <ErrorWrapper className={error ? 'error' : ''}>
        <StyledSelect {...props} {...searchablePropSet} components={{ SelectSearchableContainer }} options={options} />
        <ErrorMessage aria-label="error">{error && error}</ErrorMessage>
      </ErrorWrapper>
      {hasBorder && <HorizontalBorder />}
    </Wrapper>
  );
};

BaseSelect.propTypes = {
  options: PropTypes.array,
  onChange: PropTypes.func.isRequired,
  allowSelectAll: PropTypes.bool,
  isExportVisit: PropTypes.bool,
  menuPlacement: PropTypes.string,
  inputId: PropTypes.string.isRequired,
  isFocused: PropTypes.bool,
  autoFocus: PropTypes.bool,
  allOption: PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.string,
  }),
  value: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.any,
    }),
  ]),
  labelText: PropTypes.string,
  required: PropTypes.bool,
  pageColumns: PropTypes.string,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  hasBorder: PropTypes.bool,
  objStyles: PropTypes.object,
};

BaseSelect.defaultProps = {
  allOption: {
    label: 'All',
    value: '*',
  },
  options: [
    {
      label: '',
      value: '',
    },
  ],
  allowSelectAll: false,
  isExportVisit: false,
  isFocused: false,
  menuPlacement: 'bottom',
  autoFocus: false,
  value: {
    label: '',
    value: '',
  },
  required: false,
  labelText: '',
  pageColumns: '',
  error: '',
  hasBorder: false,
  objStyles: {},
};

export default memo(BaseSelect);
