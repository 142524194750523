import React, { useEffect, useCallback, useReducer, useState, useMemo, useRef } from 'react';
import moment from 'moment-timezone';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { PageContainer, EntityHeader, IsMassAccount } from 'modules';
import { Section, Picker, BaseTimePicker, Loader } from 'ui-kit';
import { notification } from 'utils/services';
import { convertVisitJSON, formatDateTimeZone, getFullYearAge, formatTimeToUtc } from 'utils/custom';
import { AuthEntity, VisitEntity } from '_entities';
import { MESSAGES, URLS } from '_constants';
import * as Survey from 'survey-react';
import 'survey-react/survey.css';
import YouthAgeModal from 'modules/Modals/YouthAgeModal';
import * as Styled from './styles';
import { PAGE_TYPE } from '../../../VisitView/constants';
import { formReducer, init, isBase64 } from '../../_utils';
import { formInitialState, FORM_ACTION_TYPES, formNames, SUBMISSION_COLUMN } from '../../constants';

const { updateVisit } = VisitEntity.actions;
const { getVisitData, getVisitInfo } = VisitEntity.selectors;
const { getTimeZone } = AuthEntity.selectors;

const getDropdownAdditionalData = (surveyForm) => {
  const surveyItem = surveyForm?.attributes?.form_json?.pages.find((item) => item.name === 'Visit Details');
  // eslint-disable-next-line no-prototype-builtins
  const isQuestions = surveyItem.hasOwnProperty('questions');
  return surveyItem[isQuestions ? 'questions' : 'elements'].find((item) => item.name === 'youth_id');
};
// eslint-disable-next-line no-unused-vars
const defineEmptyFieldValue = (fieldName, questions) => {
  const questionObject = questions.find((question) => question.name === fieldName);
  if (!questionObject) return null;
  switch (questionObject?.type) {
    case 'text':
    case 'comment':
    case 'email':
    case 'url':
    case 'phone':
    case 'expression':
    case 'html':
    case 'signaturepad':
    case 'file':
      return '';

    default:
      return null;
  }
};

const setFileFieldValue = (accValue) => {
  if (Array.isArray(accValue) && accValue[0]?.content) {
    return isBase64(accValue[0]?.content) ? accValue : accValue[0]?.content;
  }
  return accValue;
};

const setSurveyDefaultValues = (formPages, initialEditValues) => {
  const defaultData = {};

  function processQuestions(questions) {
    questions.forEach((question) => {
      if (question.name && Object.prototype.hasOwnProperty.call(question, 'value')) {
        defaultData[question.name] = question.value;
      }
    });
  }

  formPages.forEach((page) => {
    const questions = page?.questions || page?.elements;
    if (questions) {
      processQuestions(questions);
    }
  });

  const survey = new Survey.Model({
    pages: formPages,
    clearInvisibleValues: 'onHidden',
  });
  survey.isSinglePage = true;
  survey.data = defaultData;
  initialEditValues.current = defaultData;
  survey.completeText = 'Save changes';
  survey.onAfterRenderPage.add(() => {
    const surveyContainer = document.querySelector('.sv_p_container');
    if (surveyContainer) {
      const links = surveyContainer.querySelectorAll('a');
      links.forEach((link) => {
        link.setAttribute('target', '_blank');
        link.setAttribute('rel', 'noopener noreferrer');
      });
    }
  });

  return survey;
};

function VisitEditForm() {
  const [loading, setLoading] = useState(false);
  const visitData = useSelector(getVisitData);
  const timeZone = useSelector(getTimeZone);
  const { headerTitle, sectionTitle, submitUrl } = useSelector(getVisitInfo);
  const [state, formDispatch] = useReducer(formReducer, formInitialState, init);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const formStateData = state.data?.attributes;
  const formStateError = state.errors;
  const visitCreator = state.data?.visitCreator;
  const dispatch = useDispatch();
  const history = useHistory();
  const isMassAccount = IsMassAccount();
  const data = useMemo(() => convertVisitJSON(visitData), [visitData]);
  const { attributes } = data;
  const initialEditValues = useRef({});
  const surveyForm = visitData?.included?.find(({ type }) => type === 'Form');
  const survey = useMemo(() => {
    return setSurveyDefaultValues(surveyForm?.attributes?.form_json?.pages, initialEditValues);
  }, [surveyForm]);

  useEffect(() => {
    const baseSubmittedTime = formatDateTimeZone(attributes.submitted, timeZone, 'HH:mm');
    const baseOffset = parseInt(moment(attributes.submitted).format('Z')) * 60;
    const dateOffsetInMinutes = parseInt(formatDateTimeZone(attributes.submitted, timeZone, 'Z')) * 60;
    const submittedDateWithTimeZone = new Date(
      new Date(attributes.submitted).getTime() + (dateOffsetInMinutes - baseOffset) * 60 * 1000,
    );

    const payload = {
      ...data,
      attributes: {
        ...attributes,
        [formNames.submitted]: submittedDateWithTimeZone,
        [formNames.submittedTime]: baseSubmittedTime,
      },
    };
    formDispatch({ type: FORM_ACTION_TYPES.SET_INITIAL_DATA, payload });
  }, []);

  const handleChangeTime = (time) => {
    const payload = time || '';
    formDispatch({ type: FORM_ACTION_TYPES.ENTER_DATA, name: formNames.submittedTime, payload });
  };

  const handlePickerChange = useCallback(
    (date) => {
      formDispatch({ type: FORM_ACTION_TYPES.ENTER_DATA, name: formNames.submitted, payload: date });
    },
    [formDispatch],
  );

  const handleCloseModal = () => {
    setIsModalOpen(() => false);
  };

  const handleSubmit = async (surveyObj) => {
    formDispatch({ type: FORM_ACTION_TYPES.VALIDATE_DATA });
    survey.clear(false);
    setLoading(true);
    const finalData = Object.keys(initialEditValues.current).reduce((acc, key) => {
      const accValue = Object.prototype.hasOwnProperty.call(surveyObj?.data || {}, key)
        ? surveyObj.data[key]
        : defineEmptyFieldValue(key, data?.questions);

      acc[key] = accValue === 'null' ? null : setFileFieldValue(accValue);
      return acc;
    }, {});

    const updatedData = {
      data: {
        attributes: {
          ...finalData,
          visit_time: formatTimeToUtc({
            date: formStateData.submitted,
            timeZone,
            hoursTime: formStateData[formNames.submittedTime],
          }),
          modified: formatTimeToUtc({
            date: new Date(),
            timeZone,
            hoursTime: formStateData[formNames.submittedTime],
          }),
        },
        id: visitData?.data?.id,
        type: 'Visit',
      },
    };

    try {
      await dispatch(updateVisit(updatedData, visitData.data.id));
      notification.success(MESSAGES.VISIT.EDIT.SUCCESS);
      history.push(`${submitUrl}/${visitData.data.id}`);
    } catch (err) {
      notification.error('Something went wrong during the request');
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const handleValueChange = (surveyObj, options) => {
    if (options.name === 'youth_id' && isMassAccount) {
      const birthdate = survey.getQuestionByName('youth_birthdate');
      const gender = survey.getQuestionByName('youth_gender');
      const optionMetaData = getDropdownAdditionalData(surveyForm);
      if (options.value === '') {
        birthdate.value = '';
        gender.value = '';
        birthdate.setPropertyValue('readOnly', false);
        gender.setPropertyValue('readOnly', false);
      } else {
        if (getFullYearAge(optionMetaData.birthdate[options.value]) > 20) {
          setIsModalOpen(() => true);
        }
        birthdate.value = optionMetaData.birthdate[options.value];
        gender.value = optionMetaData.gender[options.value];
        birthdate.setPropertyValue('readOnly', true);
        gender.setPropertyValue('readOnly', true);
      }
    }
  };

  if (loading) return <Loader />;

  return (
    <PageContainer>
      <Styled.Wrapper>
        <EntityHeader data={visitData} pageType={PAGE_TYPE.EDIT} title={headerTitle} />
        <Styled.RowWrapper>
          <Section title={sectionTitle}>
            <Survey.Survey
              model={survey}
              showCompletedPage={false}
              onComplete={handleSubmit}
              onValueChanged={handleValueChange}
            />
          </Section>
          <Section title="Submission information">
            <Styled.Dl isSubmission>
              <Styled.InputWrapper>
                <Picker
                  label="Submitted date (mm/dd/yyyy)*"
                  showMonthYearDropdown
                  idInput="closeDay"
                  onChange={handlePickerChange}
                  selected={formStateData?.submitted || null}
                  error={formStateError[formNames.submitted]}
                  inputFor="closeDate"
                  isFullWidth
                  name={formNames.submitted}
                  required
                  pageColumns={SUBMISSION_COLUMN}
                />
              </Styled.InputWrapper>
              <Styled.InputWrapper>
                <BaseTimePicker
                  onChange={(time) => handleChangeTime(time)}
                  error={formStateError?.submitted_time}
                  value={formStateData?.submitted_time}
                  label="Submitted time*"
                  name={formNames.submittedTime}
                  pageColumns={SUBMISSION_COLUMN}
                />
              </Styled.InputWrapper>
              <Styled.SubmissionItemWrapper>
                <Styled.Dt>Completed by</Styled.Dt>
                <Styled.Dd>
                  <Link
                    to={`${URLS.users}/${visitCreator?.id}`}
                  >{`${visitCreator?.first_name} ${visitCreator?.last_name}`}</Link>
                </Styled.Dd>
              </Styled.SubmissionItemWrapper>
            </Styled.Dl>
          </Section>
        </Styled.RowWrapper>
      </Styled.Wrapper>
      <YouthAgeModal isOpen={isModalOpen} onClose={handleCloseModal} />
    </PageContainer>
  );
}

export default VisitEditForm;
