import styled, { createGlobalStyle, css } from 'styled-components';
import { DEVICES } from '_constants';
import { deviceLess } from '_constants/DEVICES';
import arrowLeft from './icons/datepicker-arrow-left.svg';
import arrowRight from './icons/datepicker-arrow-right.svg';

export const UiColors = {
  blackFillColor: '#0D0D0D',
  alabasterFillColor: '#f9f9f9',
  greyFillColor: '#E5E5E5',
  dimGreyFillColor: '#737373',
  silverFillColor: '#BDBDBD',
  whiteSmokeFillColor: '#f1f1f1',
  fillWhiteColor: '#FFFFFF',
  strokeColor: '#E0E0E0',
  errorColor: '#D33535',
  notificationBackgroundColor: '#3A3E43',
  footerBackgroundColor: '#F9F9F9',
  navyColor: '#1F2543',
  peachColor: '#FFEDDC',
  orangeColor: '#E6694A',
  mainBlueFillColor: '#5968B2',
  focusColor: `#1787e0`,
  disabledButtonColor: `#f5f5f5`,
  hoverInput: `#E0E0E0`,
  fontColor: `#4D4D4D`,
  cloudColor: `#F7F2F0`,
  activeBorder: `#B3B3B3`,
  fontColorGray: `#BBBBBB`,
  dataFill: `#828282`,
  gray: `#E0E0E0`,
  darkColor: `#3A3E43`,
  lightGreen: `#E9FFE5`,
  milk: `#FFF5F5`,
  green: `#477D3B`,
  grayFill: `#4F4F4F`,
  whiteGray: `#fafafa`,
  borderGray: `#cbcbcb`,
  backGray: `#ebebeb`,
  underline: `#EBEBEB`,
  headerTableGray: `#F2F2F2`,
  mangoColor: '#FFB68C',
  forestColor: '#2B4E56',
  focusedColor: `#bcd7fb`,
  brownColor: `#89969F`,
};

export const Indents = {
  containerIndentTablet: '25px',
  containerIndentMobileL: '10px',
};

const Global = createGlobalStyle`
  html, body {
    margin: 0;
    padding: 0;
    height: 100%;
    width: 100%;
    overflow-y: auto;
    overflow-x:hidden;
    font-family: Roboto;
    @media print {
      ::-webkit-scrollbar { width: 0 !important }
      -ms-overflow-style: none;
      overflow: -moz-scrollbars-none;
    }
  }

  div:focus, a:focus {
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      border: 1px dotted black !important;
   }

    @media not all and (min-resolution:.001dpcm) { @media {
      border: 2px solid ${UiColors.focusedColor};
  }}
  @-moz-document url-prefix() {
    border: 2px solid ${UiColors.focusedColor};
  }

  }

  @-moz-document url-prefix() {
    button:focus {
      border: 2px solid ${UiColors.focusedColor};
    }
  }

  @media not all and (min-resolution:.001dpcm) { @media {
    button:focus { 
      border: 2px solid ${UiColors.focusedColor};
    }
}}

  #root { 
  input::-ms-clear {
    display: none;
  }

  min-height: 100vh;
  display: flex;
  flex-direction: column;
  // height:100%;
  }
  
  input {
    font-size: 13.5px;
  }
`;

export const AppContainer = styled.div`
  flex: 1 0 auto;
`;

export const AdaText = styled.span`
  display: none;
`;

export const InputWrap = styled.div.attrs(() => ({
  ariaRequired: true,
}))`
  position: relative;
  width: ${({ isFullWidth }) => (isFullWidth ? '100%' : '45%')};
  padding: 0px;
  margin: ${({ isFullWidth }) => (isFullWidth ? '0 10px 0 0' : '0 10px')};
  margin-left: ${({ isMargin }) => isMargin && '0'};
  @media ${DEVICES.tablet} {
    ${({ type }) =>
      type === 'license' &&
      css`
        margin: 10px 0;
      `}
    ${({ type }) =>
      type === 'addSuspension' &&
      css`
        width: 100%;
        margin: 20px 0 0;
      `}
  }
`;

export const CustomInputWrapper = styled.div`
  position: relative;

  input::-ms-clear {
    display: none;
  }
`;
export const Main = styled.main`
  @media ${deviceLess.desktopHd} {
    display: flex;
    flex-direction: column;
    max-width: 1920px;
    margin: auto;
  }
`;

export const CalendarIconWrapper = styled.div`
  position: absolute;
  right: 8px;
  top: 8px;
  cursor: pointer;
  img {
    width: 15px;
    height: 16px;
  }
`;

export const ReactPickerWrapper = styled.div`
  .react-datepicker-popper {
    z-index: 99999;
    ${({ isMerger, isSuspension }) =>
      (isMerger || isSuspension) &&
      css`
        top: 60px !important;
      `};

    @media ${DEVICES.tablet} {
      position: fixed !important;
      top: 50% !important;
      left: 50% !important;
      transform: translate(-50%, -50%) !important;
    }
  }
  .react-datepicker {
    border: ${({ isError }) => isError && '1px solid #D33535 !important'};
    width: 388px;
    ${({ isMerger }) =>
      isMerger &&
      css`
        width: 300px;
      `};
    font-size: 14px;
    padding: 65px 25px 15px;
    border-radius: 0;
    box-sizing: border-box;
    @media ${DEVICES.laptop} {
      width: 350px;
      padding: 32px 8px 5px;
      ${({ isMerger }) =>
        isMerger &&
        css`
          width: 400px;
        `};
    }
    @media ${DEVICES.tablet} {
      ${({ isMerger }) =>
        isMerger &&
        css`
          width: 250px;
        `};
    }
    @media ${DEVICES.mobileXL} {
      font-size: 12px;
      ${({ isMerger }) =>
        isMerger &&
        css`
          width: 200px;
        `};
      padding: 22px 8px 8px;
    }
    @media ${DEVICES.mobileXL} {
      width: 250px;
    }
    &__day-name,
    &__day,
    &__time-name {
      line-height: 1.75;
    }
    &-wrapper {
      display: block;
    }
    &__header {
      position: relative;
      padding-top: 8px;
      background: #fff;
      border-color: #000;
      &__dropdown {
        position: absolute;
        left: 0;
        top: -40px;
        @media ${DEVICES.laptop} {
          top: -19px;
        }
        @media ${DEVICES.mobileL} {
          top: -15px;
          left: -12px;
        }
      }
    }
    &__current-month {
      display: none;
    }
    &__month-container {
      float: none;
      width: 100%;
    }
    &__month-year-read-view {
      &--down-arrow {
        margin-top: -2px;
        @supports (display: grid) {
          margin-right: 0;
        }
        margin-right: -10px;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 4px 4px 0 4px;
        border-color: #3a3e43 transparent transparent transparent;
        &:before {
          display: none;
        }
      }
    }
    &__triangle {
      @media ${DEVICES.tablet} {
        display: none;
      }
    }
    &__navigation {
      width: 12px;
      height: 12px;
      border: none;
      top: 28px;
      @media ${DEVICES.laptop} {
        top: 19px;
      }
      @media ${DEVICES.mobileXL} {
        top: 5px;
      }
      &--previous {
        left: auto;
        right: 75px;
        background: url(${arrowLeft}) 50% 50%;
        background-size: contain;
        background-repeat: no-repeat;
        @media ${DEVICES.mobileXL} {
          right: 40px;
        }
      }
      &--next {
        right: 40px;
        background: url(${arrowRight}) 50% 50%;
        background-size: contain;
        background-repeat: no-repeat;
        @media ${DEVICES.mobileXL} {
          right: 20px;
        }
      }
    }
    &__month-year {
      &-dropdown {
        top: 0;
        left: 0;
        width: 100%;
        font-size: 14px;
        height: 270px;
        overflow: auto;
        background-color: #fff;
        border-radius: 0;
        border: 1px solid ${UiColors.activeBorder};
        @media ${DEVICES.mobileL} {
          font-size: 12px;
        }
        &-container {
          min-width: 120px;
          top: 20px;
        }
      }
      &-option {
        padding: 12px 16px;
        text-align: left;
        box-sizing: border-box;
        &:hover {
          background: ${UiColors.footerBackgroundColor};
        }
        &--selected {
          display: none;
        }
      }
    }
    &__month {
      @media ${DEVICES.mobileXL} {
        margin: 0.4rem 0 0;
      }
    }
    &__day-names,
    &__week {
      display: flex;
      justify-content: space-around;
    }
    &__day {
      font-size: 14px;
      width: 31px;
      height: 29px;
      @media ${DEVICES.laptop} {
        font-size: 12px;
        width: 25px;
        height: 24px;
      }

      @media ${DEVICES.mobileXL} {
        height: 14px;
        width: 14px;
        line-height: 1;
        font-size: 11px;
      }
      &:hover {
        border-radius: 0;
      }
      &--selected {
        border-radius: 0;
        background-color: ${UiColors.errorColor};
      }
      &--keyboard-selected {
        color: #000;
        background: none;
      }
      &--outside-month {
        color: black;
        opacity: 0.7;
      }
    }
    &__day-name {
      font-weight: bold;
      @media ${DEVICES.mobileXL} {
        line-height: 1;
        font-size: 11px;
      }
    }
    &__input-container {
      position: relative;
      input {
        font-family: Roboto;
        width: 100%;
        height: 38px;
        font-size: 16px;
        border: 1px solid #e0e0e0;
        border: ${({ isError }) => isError && '1px solid #D33535'};
        ${(props) => props.disabled && `background: #f2f2f2`};
        padding: 0 11px;
        box-sizing: border-box;
      }
    }
    &__close-icon {
      &:before,
      &:after {
        position: absolute;
        top: 9px;
        right: 35px;
        content: ' ';
        height: 15px;
        width: 2px;
        background-color: #3a3e43;
      }
      &:before {
        transform: rotate(45deg);
      }
      &:after {
        padding: 0;
        border-radius: 0;
        transform: rotate(-45deg);
      }
    }
  }
`;

export const CalendarLabel = styled.div.attrs(() => ({
  ariaRequired: true,
}))`
  font-size: 14px;
  margin-bottom: 5px;
  white-space: nowrap;
`;

export const ADAlabel = styled.label`
  display: none;
`;

export const FieldLabel = styled.label`
  margin-bottom: 5px;
  display: block;
  font-size: 14px;
`;

export const FieldError = styled.div`
  font-size: 14px;
  color: ${UiColors.errorColor};
  margin-top: ${(props) => (props.withMargin ? '5px' : 0)};
`;

export const WrapperSelect = styled.div`
  margin-bottom: 20px;
`;

export default Global;
